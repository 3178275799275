<template>
  <div>
    <div
      class="w-full transition-all border border-black border-solid appearance-none relative flex items-center lg:hidden cursor-pointer focus:border-darkGray focus:text-darkGray focus:ring-0 p-2"
      :placeholder="$t('Product_Form_Select_Size')"
      @click="openMobileSizeSelector"
    >
      {{ choosenSizeLabel ? choosenSizeLabel : $t('Product_Form_Select_Size') }}
      <ChevronIcon class="absolute w-3 right-4" />
    </div>
    <SlideInTransitionTop duration="2000">
      <SizeSelectorMobile
        v-if="sizeSelectorModalOpen"
        :size-options="product.sizeOptions"
        :sizeguide="sizeguide"
        :product-id="product.product"
        :size-recommendation="product.sizeRecommendation"
        @openRestockModal="openRestockModal"
        @chooseSize="chooseSize"
      />
    </SlideInTransitionTop>
  </div>
</template>

<script>
import SlideInTransitionTop from '../transitions/SlideInTransitionTop.vue'
import SizeSelectorMobile from './SizeSelectorMobile.vue'
import ChevronIcon from '~/assets/icons/chevron-down-small.svg'

export default {
  name: 'SizeSelectorMobileController',
  components: {
    SlideInTransitionTop,
    SizeSelectorMobile,
    ChevronIcon,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    sizeguide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      choosenSizeLabel: null,
    }
  },
  computed: {
    sizeSelectorModalOpen: ({ $store }) =>
      $store.state.ui['size-selector-mobile'].sizeSelectorModalOpen,
  },
  methods: {
    chooseSize(size) {
      this.choosenSizeLabel = size.label
      this.$store.dispatch('ui/size-selector-mobile/hideSizeSelectorMobile')
      this.$emit('updateSize', size)
    },
    openMobileSizeSelector() {
      this.$store.dispatch(
        'ui/size-selector-mobile/showSizeSelectorMobile',
        this.product.product
      )
    },
    openRestockModal(size) {
      this.$store.dispatch('ui/restock-notification/show', {
        itemId: size.itemId,
        product: { ...this.product, size: size.label },
      })
    },
  },
}
</script>
