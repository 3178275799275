<template>
  <div
    v-if="productId === sizeSelectorProductId"
    class="fixed top-0 right-0 flex flex-col gap-10 items-center w-screen h-screen px-6 py-10 bg-white z-30 lg:hidden"
  >
    <header class="flex mb-6 justify-between w-full items-center">
      <h3 class="text-xl font-semibold tracking-widest">
        {{ $t('Product_Form_Select_Size') }}
      </h3>
      <button
        class="text-sm border-b border-black border-solid tracking-wider hover:opacity-60 transition-opacity"
        type="button"
        @click="hideSizeSelector()"
      >
        {{ $t('Global_Close') }}
      </button>
    </header>
    <div
      v-if="sizeRecommendation"
      class="py-2 lg:py-3 px-4 mb-4 w-full bg-lightGray text-xs lg:text-sm tracking-wider leading-5 text-black text-center"
    >
      <span class="font-semibold">{{ sizeRecommendation.title }}</span>
      <span>{{ sizeRecommendation.text }}</span>
    </div>
    <Sizeguide v-if="sizeguide" />
    <div class="flex flex-col gap-4">
      <SizeSelectorItem
        v-for="(size, index) in sizeOptions"
        :key="'size-' + index"
        :size="size"
        @click="$emit('chooseSize', size)"
        @openRestockModal="$emit('openRestockModal', size)"
      />
    </div>
  </div>
</template>
<script>
import Sizeguide from './Sizeguide.vue'
import SizeSelectorItem from './SizeSelectorItem.vue'

export default {
  name: 'SizeSelectorMobile',
  components: { SizeSelectorItem, Sizeguide },
  props: {
    sizeOptions: {
      type: Array,
      default: () => [],
    },
    sizeguide: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: undefined,
    },
    sizeRecommendation: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sizeSelectorProductId: ({ $store }) =>
      $store.state.ui['size-selector-mobile'].productId,
  },
  methods: {
    hideSizeSelector() {
      this.$store.dispatch('ui/size-selector-mobile/hideSizeSelectorMobile')
      this.$store.dispatch('ui/size-selector-mobile/setOriginToNull')
    },
  },
}
</script>
