<template>
  <div class="grid grid-cols-3 gap-4 w-full">
    <span class="text-start">
      {{ stockMessage }}
    </span>
    <button :disabled="noStock" @click="$emit('click')">
      <span
        :class="{
          'opacity-50': noStock,
        }"
      >
        {{ size.label }}
      </span>
    </button>
    <button
      v-if="noStock"
      class="flex gap-2 items-center"
      @click="$emit('openRestockModal')"
    >
      <MailIcon class="w-4 h-4 fill-brandRed" />
      <span class="text-brandRed text-sm">{{ $t('Notify_Me') }}</span>
    </button>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import MailIcon from '~/assets/icons/mail.svg'

export default {
  name: 'SizeSelectorItem',
  components: { MailIcon },
  props: {
    size: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      notifyMeEnabled: (state) => state.ui.notifyMeEnabled,
    }),
    noStock: ({ size }) => size.quantity === 0,
    lowStock: ({ size }) => size.quantity < 5 && size.quantity > 1,
    stockMessage: ({ lowStock, noStock, $t }) =>
      lowStock
        ? $t('Global_Low_Stock')
        : noStock
        ? $t('Global_Out_Of_Stock')
        : '',
  },
}
</script>
